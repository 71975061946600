
export default {
	data () {
		return {
			loading: false,
			phone: '',
			verify_code: '',
			second: 0,
			confirmCodeLoading: true,
			qrcodevalue: "null",
		}
	},
	computed: {
		secondrender () {
			return this.second == 0 ? "获取验证码" : this.second + "S";
		},
	},
	mounted () {
	},
	methods: {
		//查询扫码结果
		pull () {
			let that = this
			let generator = function* (that) {
				yield that.$api.confirmCodeCheckApi({ code: that.qrcodevalue })
			}
			generator(that).next().value.then(res => { // step.value是一个Promise对象
				if (res.data.confirmed) {
					this._set_userinfo({ data: res.data.user_info })
					this.$message.success("登陆成功")
					this.loginSuccess()
				} else {
					setTimeout(() => {
						this.pull()
					}, 1000)
				}
			})
		},
		//生成确认二维码
		async _confirmCode () {
			this.confirmCodeLoading = true
			const { status, info, data } = await this.$api.confirmCodeApi({
				plate_form: "4"
			});
			if (status == 200) {
				this.qrcodevalue = data.code
				this.pull()
			} else {
				this.$message({
					message: info,
					type: "error",
				});
			}
			this.confirmCodeLoading = false
		},
		// 登录请求
		async _reqLogin () {
			this.loading = true
			const { verify_code, phone } = this;
			const { status, info, data } = await this.$api.loginApi({
				verify_code,
				phone
			});

			if (status === 200) {
				this._set_userinfo({ data })
				this.$message.success("登陆成功")
				this.loginSuccess()
			} else if (status === 422) {
				this.$message({
					message: info,
					type: "error",
				});
			} else {
				this.$message({
					message: info,
					type: "warning",
				});
			}
			this.loading = false
		},
		//获取验证码
		async _reqVerifyCode () {
			const { phone } = this;
			if (phone == "") {
				this.$message({
					message: '请输入手机号',
					type: 'warning'
				});
				return;
			}
			if (this.second > 0) {
				return;
			}
			const { info, status } = await this.$api.verifyCodeApi({
				phone,
			});
			if (status === 200) {
				this.$message({
					message: "获取验证码成功",
					type: "success",
				});
				this.second = 60;
				let js = setInterval(() => {
					this.second--;
					if (this.second == 0) {
						clearInterval(js);
					}
				}, 1000);
			} else if (status === 422) {
				this.$message({
					message: info,
					type: 'error'
				});
			} else {
				this.$message({
					message: info,
					type: "warning",
				});
			}
		},
		async _set_userinfo ({ data }) {
			this.$store.commit('user/setUserInfo', {
				token: data.token,
			})
			// 储存极光账号密码
			this.$store.commit('user/setjim', [data.jm_message.username, data.jm_message.password])
			const { data: profileData } = await this.$api.profileEditApi();
			const {
				avatar,
				nick_name,
				phone,
				uuid,
				token,
				is_vip,
				user_verify,
				can_accept_anchor
			} = data
			this.$store.commit('user/setUserInfo', {
				avatar,
				nick_name,
				phone,
				uuid,
				token,
				is_vip,
				user_verify,
				can_accept_anchor,
				following: profileData.following,
				follower: profileData.follower,
				published_count: profileData.published_count
			})
			const { data: { cookie_id: parSid } } = await this.$api.shangzhiboApi();
			this.$store.commit('user/setUserInfo', {
				parSid
			})
		}
	}
}