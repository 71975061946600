import request from '@/utils/request'
export default {
	// 获取aliyun配置信息
	getAliyun (params) {
		return request.post(`/api/upload/config`, { from: 'pc', ...params })
	},
	// 获取首页轮播图
	// 	[
	// 		0 => '手机banner',
	// 		1 => '手机金刚区',
	// 		2 => '星探banner',
	// 		3 => '星探pc-banner',
	// 		4 => 'pc首页广告'
	// ]
	getHomeBanners (params) {
		return request.post(`/api/home/banners`, params ? params : { type: 4 })
	},
	// 获取单页详情
	getHomeBannersDetail (params) {
		return request.post(`/api/page/detail/${params}`)
	}
}
