//  转小写
let lower = value => value.toLowerCase();

//  转大写
let upper = value => value.toUpperCase();

let trimSpace = text => text ? text.replace(/\s+/g, "") : ''
export {
	lower,
	upper,
	trimSpace
}