import JSEncrypt from 'jsencrypt';
import Vue from 'vue'
Vue.prototype.$getRsaCode = function (str) { // 注册方法
	let pubKey = `-----BEGIN PUBLIC KEY-----
	MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDf2dwtnMx0q2G0LGcCd+5/hH1W
	mh1WjlVhwT77EdRQyXluZelkrNwdlSij17ypYIA+ylpnQ9yOpBhudSINVTa514PJ
	kEaAVE0mdQ0MrqkHzOdm3QPgbJ/6qZE02xR8TGBsNZzsezSBX9f5QSLEcPqwJd2F
	QWMFKqhjZiJVWEWG2wIDAQAB
	-----END PUBLIC KEY-----`;// ES6 模板字符串 引用 rsa 公钥
	let encryptStr = new JSEncrypt();
	encryptStr.setPublicKey(pubKey); // 设置 加密公钥
	let data = encryptStr.encrypt(str.toString());  // 进行加密
	return data;
}