import request from '@/utils/request'
export default {
	//首页推荐课程列表
	courseRecommendListApi (params) {
		return request.post(`/api/course/recommend-list`, params)
	},
	//获取课程视频播放凭证
	coursePlayAuthApi (params) {
		return request.post(`/api/course/play-auth`, params)
	},
	//课程详情接口
	courseDetailApi (params) {
		return request.post(`/api/course/detail`, params)
	},
	//学习历史
	courseStudyHistoryApi (params) {
		return request.post(`/api/course/study-history`, params)
	},
	//记录学习进度
	courseRecordWatchProgressApi (params) {
		return request.post(`/api/course/record-watch-progress`, params)
	},
	//学习进度统计
	courseStudyCountApi (params) {
		return request.post(`/api/course/study-count`, params)
	},
	//学习进度统计
	courseMineListApi (params) {
		return request.post(`/api/course/mine/list`, params)
	},
	//获取目前金币余额
	orderOrderDetailApi (params) {
		return request.post(`/api/order/order_detail`, params)
	},
	//获取金币产品表
	goodsGlodCoinApi (params) {
		return request.get(`/api/goods/glod-coin/1`, params)
	},
	//pc支付宝支付
	aliPlayApi (params) {
		return request.post(`/api/pay/start-pcpay`, params)
	},
	// 金币购买课程
	coinPayApi (params) {
		return request.post(`/api/coin/pay`, params)
	},
	//用户金币消费记录
	coinBillListApi (params) {
		return request.post(`/api/bills/coin-bill`, params)
	},
	// 兑换码验证
	verifyExchangeApi (params) {
		return request.post(`/api/exchange-code/verifyExchange`, params)
	},
	// 兑换码兑换课程
	exchangeCodeCourseApi (params) {
		return request.post(`/api/exchange-code/Course`, params)
	}
}