import request from '@/utils/request'
export default {
	// 获取王牌运营列表
	kingListApi (params) {
		return request.post(`/api/star/home/broker-list`, params)
	},
	// 获取星探技巧
	xtTecApi (params) {
		return request.post(`/api/star/home/article-list`, params)
	},
	// 获取推荐记录
	anchorRecomandApi (params) {
		return request.post(`/api/star/anchor/recommend-list`, params)
    },
    //获取主播数据
    anchorInfoApi (params) {
		return request.post(`/api/star/anchor/list`, params)
    },
    //获取推荐详情
    anRecInfoApi (params) {
        return request.post(`/api/star/anchor/recommend-detail/${params.uuid}`)
    },
    //获取主播数据界面tab平台选项
     anchorTabApi (params) {
		return request.post(`/api/star/anchor/recommend-plate-form-option`, params)
	},
	//获取星探首页banner
	bannerApi (params) {
		return request.post(`/api/star/home/banner-list`, params)
	},
	//获取星探首页主播/余额数据
	accountApi (params) {
		return request.post(`/api/star/home/dash`, params)
	},
	//获取星探Pcbanner
	bannerPcApi (params) {
		return request.post(`/api/star/home/pc-banner-list`, params)
	},
	//获取星探收益框内数据
	xtMoneyApi (params) {
		return request.post(`/api/star/withdraw/fortune`, params)
	},
	//获取星探提现列表
	xtWithdrawApi (params) {
		return request.post(`/api/star/withdraw/list`, params)
	},
	//星探发起提现
	xtGetMoney (params) {
		return request.post(`/api/star/withdraw/withdraw`, params)
	},
	//星探获取账号支付宝信息
	xtAlipayApi (params) {
		return request.post(`/api/star/withdraw/alipay-info`, params)
	},
	//星探修改提现账户信息
	xtChangeAccount (params) {
		return request.post(`/api/star/withdraw/save-alipay`, params)
	},
	//推荐星探选择意向平台
	xtPlateForm(params) {
		return request.post(`/api/star/anchor/recommend-plate-form-option`,params)
	},
	//星探添加推荐资料
	tjInfo(params) {
		return request.post(`/api/star/anchor/recommend`,params)
	},
	//星探编辑推荐主播资料
	editXtInfo(params) {
		return request.post(`/api/star/anchor/recommend-update/${params.broker_id}`,params)
	}
}