import request from '@/utils/request'
export default {
	// 发布图片动态
	publishImageApi (params) {
		return request.post(`/api/moment/publish/image`, params)
	},
	//发布文章
	publishArticleApi (params) {
		return request.post(`/api/moment/publish/article`, params)
	},
	// 发布视频（长视频）
	publishVideoApi (params) {
		return request.post(`/api/moment/publish/video`, params)
	},
	//发布小视频
	publishShortVideoApi (params) {
		return request.post(`/api/moment/publish/short-video`, params)
	},
	//发布动态转发
	publishMomentShareApi (params) {
		return request.post(`/api/moment/share`, { share_type: 'moment', ...params })
	},
	//专栏列表下拉菜单
	columnListOptionApi (params) {
		return request.post(`/api/moment/column/list`, params)
	},
	//添加专栏
	addColumnApi (params) {
		return request.post(`/api/moment/column/store`, params)
	},
	// 请求动态列表
	momentListApi (params) {
		if (params.profile_tab === 4)
			delete params.user
		return request.post(`/api/moment/list`, { version: 2, ...params })
	},
	// 请求动态详情
	momentDetailApi (params) {
		return request.post(`/api/moment/detail/${params.uuid}`)
	},
	// 请求动态点赞
	momentLikeApi (params) {
		return request.post(`/api/like/model-like`, { model_type: params.model_type ? params.model_type : 'moment', ...params })
	},
	// 请求评论列表
	commentListApi (params) {
		return request.post(`/api/comment/list`, { model_type: params.model_type ? params.model_type : 'moment', ...params })
	},
	// 添加评论
	setCommentApi (params) {
		return request.post(`/api/model/comment`, { model_type: params.model_type ? params.model_type : 'moment', ...params })
	},
	// 请求评论child
	commentChildApi (params) {
		return request.post(`/api/comment/detail/${params.comment_id}`)
	},
	// 请求推荐列表
	recommendListApi (params) {
		return request.post(`/api/moment/recommend`, params)
	},
	// 请求动态收藏
	momentCollectApi (params) {
		return request.post(`/api/user/model-collect`, { model_type: params.model_type ? params.model_type : 'moment', ...params })
	},
	// 删除动态
	momentDeleteApi (params) {
		return request.post(`/api/moment/delete/${params.uuid}`)
	},
	// 推荐关注
	friendRecommendApi (params) {
		return request.post(`/api/home/friend-recommend`, params)
	}
}