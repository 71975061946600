import request from '@/utils/request'
export default {
	// 关注用户
	followApi (params) {
		return request.post(`/api/friends/follow/${params.user_id}`)
	},
	// 取消关注用户
	unfollowApi (params) {
		return request.post(`/api/friends/unfollow/${params.user_id}`)
	},
	// 取消关注用户
	profileApi (params) {
		return request.post(`/api/user/profile/${params.uuid}`)
	},
	// 关注列表
	followedListApi (params) {
		return request.post(`/api/friends/followed-list/${params.uuid}`)
	},
	// 粉丝列表
	followerListApi (params) {
		return request.post(`/api/friends/follower-list/${params.uuid}`)
	},
	// 更新用户个人信息（pc）
	infoUpdateApi (params) {
		return request.post(`/api/user/info-update`, params)
	},
	// 更新用户头像
	infoAvatarUpdateApi (params) {
		params.column = "avatar"
		return request.post(`/api/user/profile-update`, params)
	},
	// 个人中心专栏列表
	columnProfileListApi (params) {
		return request.post(`/api/column/profile/list`, params)
	},
	// 是否有新未读通知
	hasNewApi (params) {
		return request.post(`/api/notice/has-new`, params)
	},
	//通知未读数量
	newNoticeApi (params) {
		return request.post(`/api/notice/new-notice`, params)
	},
	// 用户中心点赞列表
	userLikeListApi (params) {
		return request.post(`/api/user/like-list`, params)
	},
	// 用户中心系统通知列表
	noticeSystemNoticeApi (params) {
		return request.post(`/api/notice/system-notice`, params)
	},
	// 用户中心互动消息列表
	userCommentListApi (params) {
		return request.post(`/api/user/comment-list`, params)
	},

}
