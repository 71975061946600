<template>
  <div id="app">
    <router-view />
    <!-- <div v-show="$route.name !== 'wellcome'">
      <phone class="animate__animated animate__fadeInUpBig" />
      <wechart class="animate__animated animate__fadeInUpBig" style="transition-delay: .5s;" />
      <down class="animate__animated animate__fadeInUpBig" style="transition-delay: .5s;" />
    </div> -->
    <login />
  </div>
</template>

<script>
// import phone from "@/components/btn/phone";
// import wechart from "@/components/btn/wechart";
// import down from "@/components/btn/down";
import login from "@/views/login/login";
export default {
  name: 'app',
  components: {
    // phone,
    // wechart,
    login,
    // down
  },
  methods: {
  },
  created () {
  }
}
</script>

<style lang="scss">
@import "~@/assets/style/reset.less";
@import "~@/assets/style/main.less";
@import url("./assets/iconfont/iconfont.css");
#chatBtn {
  border-radius: 0px !important;
  transition: background 0.7s;
  background-color: rgba(91, 121, 154, 0.5) !important;
  width: 60px !important;
  height: 65px !important;
  padding: 0px !important;
  line-height: 65px !important;
  &:hover {
    background-color: rgba(91, 121, 154, 1) !important;
  }
}
</style>
