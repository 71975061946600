import axios from "axios"
import store from '../store'
// import qs from "qs"
// import Router from '@/router'
import { Message } from 'element-ui';
import utils from './index.js';
axios.defaults.timeout = 0

// http request 请求拦截器
axios.interceptors.request.use(
	config => {
		// 过滤上送参数为''或者Null
		// if (config.data && config.url !== "api/upload/image" && config.url !== "api/import/invite-state" && config.url !== "api/import/import") {
		//   let params = {}
		//   for (let key in config.data) {
		//     if (config.data[key] || config.data[key] === 0) {
		//       params[key] = config.data[key]
		//     }
		//   }
		//   config.data = params
		// }
		const vuex = utils.$getStorage("vuex-along")
		if (Object.keys(vuex).length !== 0) {
			const { userInfo } = vuex.root.user
			if (userInfo)
				config.headers['Authorization'] = 'Bearer ' + userInfo.token
		}
		// config.headers['Content-Type'] = 'application/json;charset=UTF-8;'
		return config
	},
	err => {
		return Promise.reject(err)
	}
)
//http response 响应拦截器
axios.interceptors.response.use(
	response => {
		return response
	},
	error => {
		return Promise.resolve(error.response)
	}
)
//check http状态码
function checkStatus (response) {
	if (response && ((response.status >= 200 && response.status < 300) || response.status === 304 || response.status === 400)) {
		if (response.data.status == 401) {
			store.commit('layouts/changeLoginVisible', true)
			store.commit('user/clearUserInfo')

			return {
				status: 401,
				info: '请先登录'
			}
		} else {
			return response.data
		}
	}

	// if (response && response.status === 401) {
	// 	store.commit('layouts/changeLoginVisible', true)
	// 	return
	// }
	if (response && response.status) {
		if (response.status == 422) {
			let message = {}
			Object.keys(response.data.errors).forEach(item => {
				message[item] = response.data.errors[item][0]
			})
			return {
				status_code: response.status,
				message
			}
		}
		if (response.status == 403) {
			return {
				status_code: response.status,
				message: '没有权限'
			}
		}
		Message.error({ message: '网络异常，请重试' })
		return {
			status: 404,
			msg: "网络异常",
			originalStatusCode: response.status
		}
	}
}
//check apiCode码
function checkCode (res) {
	return res
}

export default {
	post (url, data) {
		return axios({
			method: "post",
			url,
			data: data,
			headers: {
				'Content-Type': 'application/json;charset=UTF-8'
			},
		})
			.then(response => {
				return checkStatus(response)
			})
			.then(res => {
				return checkCode(res)
			})
	},
	upload (url, data) {
		return axios({
			method: "post",
			url,
			data: data,
		})
			.then(response => {
				return checkStatus(response)
			})
			.then(res => {
				return checkCode(res)
			})
	},
	get (url, params) {
		return axios({
			method: "get",
			url,
			params
		})
			.then(response => {
				return checkStatus(response)
			})
			.then(res => {
				return checkCode(res)
			})
	}
}
