export default [{
	path: '/homepage',
	component: () => import('@/views/homepage'),
	name: 'homepage',
	meta: {
		title: '首页'
	}
},
{
	path: '/homepage/imageDetail',
	component: () => import('@/views/homepage/detail/imageDetail'),
	name: 'imageDetail',
	meta: {
		title: '微头条详情'
	}
},
{
	path: '/homepage/articleDetail',
	component: () => import('@/views/homepage/detail/articleDetail'),
	name: 'articleDetail',
	meta: {
		title: '文章详情'
	}
},
{
	path: '/homepage/videoDetail',
	component: () => import('@/views/homepage/detail/videoDetail'),
	name: 'videoDetail',
	meta: {
		title: '视频详情'
	}
},
{
	path: '/homepage/shortVideoDetail',
	component: () => import('@/views/homepage/detail/shortVideoDetail'),
	name: 'shortVideoDetail',
	meta: {
		title: '小视频详情'
	}
},
{
	path: '/homepage/transpondDetail',
	component: () => import('@/views/homepage/detail/transpondDetail'),
	name: 'transpondDetail',
	meta: {
		title: '转发详情'
	}
},
{
	path: '/homepage/demo',
	component: () => import('@/views/homepage/demo'),
	name: 'demo',
	meta: {
		title: 'demo'
	}
},
{
	path: '/homepage/chat',
	component: () => import('@/views/homepage/chat'),
	name: 'chat',
	meta: {
		title: '聊天'
	},
	children: [
		{
			path: '/homepage/chat/:id',
			component: () => import('@/views/homepage/chat/chatContent/index.vue'),
			name: 'chatContent',
			meta: {
				title: '聊天内容'
			}
		}
	]
},
{
	path: "/homepage/notice",
	component: () => import("@/views/homepage/notice/notice.vue"),
	name: "notice",
	meta: {
		title: '个人中心',
	},
	redirect: '/homepage/notice/notice',
	children: [
		{
			path: '/homepage/notice/notice',
			component: () => import('@/views/homepage/notice/notice/index.vue'),
			name: 'notice',
			meta: {
				title: '消息中心',
				noticeActive: "notice"
			},
			redirect: '/homepage/notice/notice/noticeLike',
			children: [
				{
					path: '/homepage/notice/notice/noticeLike',
					component: () => import('@/views/homepage/notice/notice/noticeLike/noticeLike.vue'),
					name: 'like',
					meta: {
						title: '收到的赞',
						noticeActive: "notice"
					}
				},
				{
					path: '/homepage/notice/notice/noticeSystem',
					component: () => import('@/views/homepage/notice/notice/noticeSystem/noticeSystem.vue'),
					name: 'system',
					meta: {
						title: '系统通知',
						noticeActive: "notice"
					}
				},
				{
					path: '/homepage/notice/notice/noticeComment',
					component: () => import('@/views/homepage/notice/notice/noticeComment/noticeComment.vue'),
					name: 'comment',
					meta: {
						title: '互动消息',
						noticeActive: "notice"
					}
				}
			]
		},
		{
			path: '/homepage/notice/wallet',
			component: () => import('@/views/homepage/notice/wallet/index.vue'),
			name: 'wallet',
			meta: {
				title: '我的钱包',
				noticeActive: "wallet"
			}
		},
		{
			path: '/homepage/notice/college',
			component: () => import('@/views/homepage/notice/college/index.vue'),
			name: 'college',
			meta: {
				title: '学习中心',
				noticeActive: "college"
			}
		},
		// {
		// 	path: '/homepage/notice/noticeLike',
		// 	component: () => import('@/views/homepage/notice/noticeLike/noticeLike.vue'),
		// 	name: 'like',
		// 	meta: {
		// 		title: '收到的赞'
		// 	}
		// },
		// {
		// 	path: '/homepage/notice/noticeSystem',
		// 	component: () => import('@/views/homepage/notice/noticeSystem/noticeSystem.vue'),
		// 	name: 'system',
		// 	meta: {
		// 		title: '系统通知'
		// 	}
		// },
		// {
		// 	path: '/homepage/notice/noticeComment',
		// 	component: () => import('@/views/homepage/notice/noticeComment/noticeComment.vue'),
		// 	name: 'comment',
		// 	meta: {
		// 		title: '互动消息'
		// 	}
		// }
	]
},
{
	path: '/homepage/user',
	component: () => import('@/views/homepage/user'),
	name: 'user',
	meta: {
		title: '用户页面'
	},
	redirect: '/homepage/user/personal',
	children: [
		{
			path: '/homepage/user/personal',
			component: () => import('@/views/homepage/user/personal'),
			name: 'personal',
			meta: {
				title: '个人中心'
			}
		},
		{
			path: '/homepage/user/followe',
			component: () => import('@/views/homepage/user/followe'),
			name: 'followe',
			meta: {
				title: '关注粉丝'
			}
		},
		{
			path: '/homepage/user/profileEdit',
			component: () => import('@/views/homepage/user/profileEdit'),
			name: 'profileEdit',
			meta: {
				title: '编辑资料'
			}
		},
	]
}
]