/* 
 * @Author: 曹俊杰 
 * @Date: 2020-11-02 18:56:09
 * @Module: login 弹出框
 */
 <template>
  <el-dialog :title="type=='phone'?'验证码登录':'扫码登录'" :visible.sync="loginVisible" width="380px" class="login">
    <div class="login-box" v-loading="loading">
      <div v-show="type=='phone'">
        <div class="login-box-toast">未注册登录后自动注册</div>
        <el-input v-model="phone" placeholder="手机号码、仅支持大陆手机号码" maxlength="11" style="margin-top:30px;"></el-input>
        <div class="login-box-code">
          <el-input v-model="verify_code" class="login-box-code-l" maxlength="4" placeholder="请输入验证码" @keyup.enter.native="_reqLogin"></el-input>
          <div class="login-box-code-r hover" @click="_reqVerifyCode">
            {{secondrender}}
          </div>
        </div>
        <div class="login-box-btn hover" @click="_reqLogin" :class="{'login-box-btn-ok':phone&&verify_code}">登录</div>
      </div>
      <div v-show="type=='qcode'" class="login-box-qcode">
        <qrcode-vue :value="qrcodevalue" v-loading="confirmCodeLoading" class="loginmodule-qrcode" :size="190" @click.native="_confirmCode" level="H"></qrcode-vue>
      </div>
      <div class="login-box-switch hover" v-show="type=='phone'" @click="()=>click('qcode')">扫一扫登录</div>
      <div class="login-box-switch hover" v-show="type=='qcode'" @click="()=>click('phone')">验证码登录</div>
    </div>
  </el-dialog>
</template>
 <script>
import QrcodeVue from 'qrcode.vue'
import loginmixins from "./loginmixins.js"

export default {
  components: {
    QrcodeVue
  },
  mixins: [loginmixins],
  data () {
    return {
      phone: '',
      code: '',
      type: 'phone'
    };
  },
  mounted () {
    this.type = 'phone'
  },
  methods: {
    click (type) {
      this.type = type
      if (type == "qcode") {
        this._confirmCode()
      }
    },
    loginSuccess () {
      this.$store.commit('layouts/changeLoginVisible', false)
    }
  },
  computed: {
    loginVisible: {
      get: function () {
        return this.$store.state.layouts.loginVisible
      },
      set: function (res) {
        this.$store.commit('layouts/changeLoginVisible', res)
      }
    }
  },
  watch: {
    loginVisible: {
      handler: function (val) {
        if (val) {
          this.qrcodevalue = 'null'
          this.phone = ''
          this.code = ''
          this.type = 'phone'
        }
      },
      // 深度观察监听
      deep: true,
      immediate: true
    }
  },
};
 </script>
 <style lang='scss' scoped>
.login /deep/ .el-dialog__body {
  padding: 30px;
  padding-top: 0px;
}
.login /deep/ .el-dialog__header {
  padding: 30px 18px 0px 30px;
}
.login /deep/ .el-dialog {
  border-radius: 6px;
}
.login-box {
  margin-top: 7px;
  &-toast {
    font-size: 12px;
    color: #333;
  }
  &-code {
    margin-top: 20px;
    display: flex;
    &-l {
      width: 204px;
    }
    &-r {
      width: 106px;
      line-height: 40px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #e3e3e3;
      margin-left: 10px;
      text-align: center;
      color: #999;
    }
  }
  &-btn {
    width: 320px;
    line-height: 40px;
    background: #e0e0e0;
    border-radius: 4px;
    margin-top: 36px;
    color: #fff;
    text-align: center;
    font-size: 16px;
    transition: all 1s;
    &-ok {
      background: #ff0000;
    }
  }
  &-switch {
    margin-top: 10px;
    text-align: center;
    font-size: 12px;
  }
  &-qcode {
    height: 220px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>