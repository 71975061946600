

const state = {
	userInfo: null,
	jim: [],
	administrator: false
}

const mutations = {
	setUserInfo (state, payload) {
		state.userInfo = {
			...state.userInfo,
			...payload
		}
	},
	setjim (state, payload) {
		state.jim = payload
	},
	clearUserInfo (state) {
		state.userInfo = null
		state.jim = []
	},
	openAdministrator (state, payload) {
		if (payload === "close") {
			state.administrator = false
		} else {
			state.administrator = true
		}
	}
}

const actions = {

}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}