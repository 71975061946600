import Vue from 'vue'
import Router from 'vue-router'
import certification from './certification' //资质查询
import laboratory from './laboratory'  // 实验室
import about from './about'  // 关于我们
import download from './download'  // 关于我们
import homepage from './homepage'  // 首页
import star from './star'  // 星探
import promotion from './promotion'  // 广告推广
import seachList from './seachList'  // 搜索列表
import publish from './publish'  // 发布动态
import develop from './develop'  // 正在开发
import toBroker from './toBroker'  // 发布动态
import college from './college'  // 格调学院
import aliplay from "./aliplay"
Vue.use(Router)

export default new Router({
	mode: 'history',
	routes: [
		{
			path: '/',
			component: () => import('@/views/wellcome.vue'),
			name: 'wellcome'
		},
		{
			path: '/menu',
			component: () => import('@/views/menu.vue'),
			name: 'menu'
		},
		{
			path: '/index',
			name: 'layouts',
			redirect: '/homepage',
			component: () => import('@/layouts'),
			children: [
				...certification,
				...about,
				...download,
				...promotion,
				...seachList,
				...homepage,
				...star,
				...develop,
				...college
			],
		},
		{
			path: '/adDetail',
			name: '广告详情',
			component: () => import('@/views/homepage/adDetail'),
		},
		...laboratory,
		...publish,
		...toBroker,
		...aliplay
	]
})
