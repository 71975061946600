
// 获取Local
const $getStorage = key => {
	return JSON.parse(localStorage.getItem(key))
};
// 储存Local
const $setStorage = (key, value) => {
	localStorage.setItem(key, JSON.stringify(value));
};

// 格式化时间
const $timeFormat = (timestamp = null, fmt = 'yyyy-mm-dd') => {
	// 其他更多是格式化有如下:
	// yyyy:mm:dd|yyyy:mm|yyyy年mm月dd日|yyyy年mm月dd日 hh时MM分等,可自定义组合
	timestamp = parseInt(timestamp);
	// 如果为null,则格式化当前时间
	if (!timestamp) timestamp = Number(new Date());
	// 判断用户输入的时间戳是秒还是毫秒,一般前端js获取的时间戳是毫秒(13位),后端传过来的为秒(10位)
	if (timestamp.toString().length == 10) timestamp *= 1000;
	let date = new Date(timestamp);
	let ret;
	let opt = {
		"y+": date.getFullYear().toString(), // 年
		"m+": (date.getMonth() + 1).toString(), // 月
		"d+": date.getDate().toString(), // 日
		"h+": date.getHours().toString(), // 时
		"M+": date.getMinutes().toString(), // 分
		"s+": date.getSeconds().toString() // 秒
		// 有其他格式化字符需求可以继续添加，必须转化成字符串
	};
	for (let k in opt) {
		ret = new RegExp("(" + k + ")").exec(fmt);
		if (ret) {
			fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
		}
	}
	return fmt;
}
let timer = null,
	flag = null;
/**
 * 节流原理：在一定时间内，只能触发一次
 * 
 * @param {Function} func 要执行的回调函数 
 * @param {Number} wait 延时的时间
 * @param {Boolean} immediate 是否立即执行
 * @return null
 */
const $throttle = (func, wait = 500, immediate = true) => {
	console.log(timer)
	if (immediate) {
		if (!flag) {
			flag = true;
			// 如果是立即执行，则在wait毫秒内开始时执行
			typeof func === 'function' && func();
			timer = setTimeout(() => {
				flag = false;
			}, wait);
		}
	} else {
		if (!flag) {
			flag = true
			// 如果是非立即执行，则在wait毫秒内的结束处执行
			timer = setTimeout(() => {
				flag = false
				typeof func === 'function' && func();
			}, wait);
		}

	}
};
const $random = (min, max) => {
	if (min >= 0 && max > 0 && max >= min) {
		let gab = max - min + 1;
		return Math.floor(Math.random() * gab + min);
	} else {
		return 0;
	}
}
const $onScroll = (callback) => {
	window.onscroll = function () {
		//变量scrollTop是滚动条滚动时，距离顶部的距离
		var scrollTop =
			document.documentElement.scrollTop || document.body.scrollTop;
		//变量windowHeight是可视区的高度
		var windowHeight =
			document.documentElement.clientHeight || document.body.clientHeight;
		//变量scrollHeight是滚动条的总高度
		var scrollHeight =
			document.documentElement.scrollHeight || document.body.scrollHeight;
		//滚动条到底部的条件
		if (scrollTop + windowHeight == scrollHeight) {
			//写后台加载数据的函数 一定要用that
			callback()
		}
	};
}
// 去除字符串空格
const $trim = (str, pos = 'both') => {
	if (pos == 'both') {
		return str.replace(/^\s+|\s+$/g, "");
	} else if (pos == "left") {
		return str.replace(/^\s*/, '');
	} else if (pos == 'right') {
		return str.replace(/(\s*$)/g, "");
	} else if (pos == 'all') {
		return str.replace(/\s+/g, "");
	} else {
		return str;
	}
}
/**
 * 判断某个原生DOM元素是否不在屏幕可见区内
 * @param {*} el 原生DOM元素
 */
const $isElementNotInViewport = function (el) {
	let rect = el.getBoundingClientRect();
	return (
		rect.top >= (window.innerHeight || document.documentElement.clientHeight) || rect.bottom <= 0
	);
};
/**
 * 本算法来源于简书开源代码，详见：https://www.jianshu.com/p/fdbf293d0a85
 * 全局唯一标识符（uuid，Globally Unique Identifier）,也称作 uuid(Universally Unique IDentifier) 
 * 一般用于多个组件之间,给它一个唯一的标识符,或者v-for循环的时候,如果使用数组的index可能会导致更新列表出现问题
 * 最可能的情况是左滑删除item或者对某条信息流"不喜欢"并去掉它的时候,会导致组件内的数据可能出现错乱
 * v-for的时候,推荐使用后端返回的id而不是循环的index
 * @param {Number} len uuid的长度
 * @param {Boolean} firstU 将返回的首字母置为"u"
 * @param {Nubmer} radix 生成uuid的基数(意味着返回的字符串都是这个基数),2-二进制,8-八进制,10-十进制,16-十六进制
 */
const $guid = (len = 32, firstU = true, radix = null) => {
	let chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
	let uuid = [];
	radix = radix || chars.length;

	if (len) {
		// 如果指定uuid长度,只是取随机的字符,0|x为位运算,能去掉x的小数位,返回整数位
		for (let i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix];
	} else {
		let r;
		// rfc4122标准要求返回的uuid中,某些位为固定的字符
		uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
		uuid[14] = '4';

		for (let i = 0; i < 36; i++) {
			if (!uuid[i]) {
				r = 0 | Math.random() * 16;
				uuid[i] = chars[(i == 19) ? (r & 0x3) | 0x8 : r];
			}
		}
	}
	// 移除第一个字符,并用u替代,因为第一个字符为数值时,该guuid不能用作id或者class
	if (firstU) {
		uuid.shift();
		return 'u' + uuid.join('');
	} else {
		return uuid.join('');
	}
}
const $imageSize = (file) => {
	return new Promise(resolve => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = (theFile) => {
			const image = new Image();
			image.src = theFile.target.result;
			image.onload = function () {
				resolve({
					width: this.width,
					height: this.height
				})
			};
		};
	})
}
const $toHHmmss = function (data) {
	var s;
	var hours = parseInt((data % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
	var minutes = parseInt((data % (1000 * 60 * 60)) / (1000 * 60));
	var seconds = (data % (1000 * 60)) / 1000;
	s = (hours < 10 ? ('0' + hours) : hours) + ':' + (minutes < 10 ? ('0' + minutes) : minutes) + ':' + (seconds < 10 ? ('0' + seconds) : seconds);
	return s;
};

export default {
	$setStorage,
	$getStorage,
	$timeFormat,
	$throttle,
	$random,
	$onScroll,
	$trim,
	$isElementNotInViewport,
	$guid,
	$imageSize,
	$toHHmmss
}