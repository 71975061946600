export default [
	{
		path: '/star',
		component: () => import('@/views/star'),
		// redirect: '/develop',
		name: 'star',
		meta: {
			title: '星探'
		},
	},
	{
		path: '/star/anchor',
		component: () => import('@/views/star/tuijian'),
		name: 'anchor',
		meta: {
			title: '主播数据'
		},
	},
	{
		path: '/star/shouyi',
		component: () => import('@/views/star/shouyi'),
		name: 'anchor',
		meta: {
			title: '我的收益'
		},
	},
]